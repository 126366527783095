@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

/*==================================================================*/
:root {
    --color-primary: #D2AB9A;
    --color-secondary: #5a5a5a;
    --color-white: #ffffff;
    --color-dark: #131313;
    --color-green: #259e2f;
    --color-light-100: #f5ede9;
    --color-gray-100: #f4f5f8;
    --color-gray-200: #e3e7ed;
    --color-gray-300: #d4d4d7;
    --color-gray-400: #e6e6e6;
    --color-gray-500: #606161;
    --color-gray-600: #3f3f40;
    --color-gray-700: #404041;
    --color-gray-800: #3b4863;
    --color-gray-900: #1c273c;
    --color-gradient01: linear-gradient(180deg, #FFF 50%, #f5ede9 50%);
    --font-primary: "PT Sans", sans-serif;
    --font-secondary: "Playfair Display", serif;
}

/*==========================================================*/
@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1240px;
    }
}

.container-fluid {
    padding-left: 25px;
    padding-right: 25px;
}

@media only screen and (max-width:968px) {
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
}

a {
    text-decoration: none;
    color: var(--color-dark);
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 200 !important;
    font-family: var(--font-secondary) !important;
}

.h1,
h1 {
    font-size: 3.5rem;
}

input:focus {
    outline: none;
}

/*==========================================================*/
.bg-primary {
    background: var(--color-primary) !important;
}

.bg-gray {
    background: var(--color-gray-100);
}

.bg-light {
    background: var(--color-light-100) !important;
}

.bg-gradient01 {
    background: var(--color-gradient01) !important;
}

.tx-primary {
    color: var(--color-primary);
}

.tx-secondary {
    color: var(--color-secondary);
}

.tx-gray {
    color: var(--color-gray-500);
}

.tx-green {
    color: var(--color-green);
}

.tx-12 {
    font-size: 12px;
}

.tx-14 {
    font-size: 14px;
}

.tx-16 {
    font-size: 16px !important;
}

.tx-18 {
    font-size: 18px;
}

.tx-left {
    text-align: left;
}

.tx-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.fw700 {
    font-weight: 700;
}

.text-uppercase {
    text-transform: uppercase;
}

.img-fluid {
    width: 100%;
}

.linebutton {
    display: inline-block;
    position: relative;
    color: var(--color-dark);
    padding: 2px 0px;
    font-size: 16px;
    font-weight: 200;
}

.linebutton.linebutton-small {
    font-size: 14px;
}

.linebutton::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: var(--color-dark);
    bottom: 0;
    left: 0;
}

.btn {
    border-radius: 0px;
}

.btn-primary {
    background: var(--color-dark);
    border-color: var(--color-dark);
}

.btn-primary:hover {
    background: var(--bs-gray-800);
    border-color: var(--bs-gray-800);
}

.btn-outline-primary {
    background: transparent;
    border-color: var(--color-secondary);
    color: var(--color-secondary);
}

.btn-outline-primary:hover {
    background: var(--color-secondary);
    border-color: var(--color-secondary);
}

.btn-outline-white {
    background: transparent;
    border-color: var(--color-white);
    color: var(--color-white);
}

.btn-outline-white:hover {
    background: var(--color-secondary);
    border-color: var(--color-secondary);
}

.btn-full {
    width: 100%;
}

.btn-md {
    padding: 12px 20px;
    font-size: 16px;
}

.btn-lg {
    padding: 15px 40px;
    font-size: 16px;
}

.overlay-zoom {
    overflow: hidden
}

.overlay-zoom figure {
    overflow: hidden
}

.overlay-zoom img {
    transition: transform 0.3s
}

.overlay-zoom:hover img {
    transform: scale(1.08)
}

.border-top {
    border-top: 1px solid var(--color-gray-300);
}

.wd-200 {
    width: 200px;
}

.wd-100 {
    width: 100px;
}

.wd-50 {
    width: 50px;
}

/*===================================================================*/
.section-title {
    width: 100%;
    position: relative;
}

.section-title .pre-title {
    font-weight: 500;
    display: inline-block;
    text-transform: uppercase;
    margin-bottom: 14px;
    font-size: 14px;
}

.section-title h1 {
    font-size: 52px;
}

.section-title h2 {
    font-size: 32px;
}

.section-title h3 {
    font-size: 22px;
}

@media only screen and (max-width:968px) {
    .section-title h1 {
        font-size: 24px;
    }

    .section-title h2 {
        font-size: 22px;
    }
}

/*==========================================================*/
.section-gap-sm {
    padding: 30px 0px;
}

.section-gap-md {
    padding: 60px 0px;
}

.section-gap-large {
    padding: 80px 0px;
}

.section-gap-medium {
    padding: 60px 0px;
}

.section-gap-small {
    padding: 30px 0px;
}

.sectiontop-gap-large {
    padding-top: 80px;
}

.sectiontop-gap-medium {
    padding-top: 60px;
}

.sectiontop-gap-small {
    padding-top: 30px;
}

.sectionbottom-gap-large {
    padding-bottom: 80px;
}

.sectionbottom-gap-medium {
    padding-bottom: 60px;
}

.sectionbottom-gap-small {
    padding-bottom: 30px;
}

/*==========================================================*/
.video-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    gap: 5%;
}

.video-section .video-section-left {
    width: 60%;
    display: flex;
    margin: auto;
    position: relative;
    flex-direction: column;
}

.video-section .video-section-right {
    width: 35%;
    display: flex;
    margin: auto;
    position: relative;
    flex-direction: column;
}

.videoBox {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.videoBox video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border: 0px;
}

.vslider {
    width: 100%;
}

.vsliderProduct {
    width: 100%;
    position: relative;
}

.vsliderProduct .vsliderProduct-media {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.vsliderProduct-media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vsliderProduct .vsliderProduct-content {
    width: 100%;
    position: relative;
}

@media only screen and (max-width:968px) {
    .video-section {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 50px;
        text-align: center;
    }

    .video-section .video-section-left {
        width: 100%;
    }

    .video-section .video-section-right {
        width: 75%;
    }
}

/*==========================================================*/
.header {
    left: 0;
    position: absolute;
    top: 0;
    transition: .3s;
    width: 100%;
    z-index: 99;
}

.header:hover {
    background: var(--color-secondary);
    transition: all .3s;
    color: var(--color-dark);
}

.header.innerHeader {
    position: relative;
    background: var(--color-secondary);
}

.header.innerHeaderWhite {
    position: relative;
    background: var(--color-white);
    padding: 10px 0px;
}

.fixed-head {
    background: var(--color-secondary) !important;
    left: 0;
    position: fixed !important;
    top: -40px;
    transform: translateY(40px);
    z-index: 999;
}

.header .header-middle {
    width: 100%;
    position: relative;
    padding: 10px 0px;
    background: transparent;
}

.header .header-bottom {
    width: 100%;
    position: relative;
}

.header .container,
.header .container-fluid,
.header .inner-wrap {
    display: flex;
    align-items: center;
}

.header-center,
.header-left,
.header-right {
    display: flex;
    align-items: center;
}

.header-left,
.header-right {
    flex: 1 1;
}

.header .logo {
    display: block;
}

.header .logo img {
    width: 75px;
    height: auto;
}

.header-middle.fixed-head .logo {
    display: block;
}

.header .logo-dark {
    display: none;
}

.header .logo-dark img {
    width: 75px;
    height: auto;
}

.header-middle.fixed-head .logo-dark {
    display: none;
}

.header .navbar {
    padding: 0;
}

.header .nav-link {
    color: var(--color-white);
    padding: 1rem 1.5rem !important;
    position: relative;
}

.header .navbar-nav .nav-link.active,
.header .navbar-nav .nav-link.show {
    color: var(--color-primary);
}

.header .nav-link::after {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    background: var(--color-white);
    transition: 0.5s;
    opacity: 0;
    bottom: 0;
    left: 0;
}

.header .nav-link:hover::after {
    opacity: 1;
    width: 100%;
    transition: 0.5s;
}

.rightHeaderlist {
    align-items: center;
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0
}

.rightHeaderlist li {
    display: inline-block;
    position: relative
}

.rightHeaderlist li a {
    color: var(--color-white);
}

.leftHeaderlist {
    align-items: center;
    display: flex;
    gap: 20px;
    list-style: none;
    margin: 0;
    padding: 0
}

.leftHeaderlist li {
    display: inline-block;
    position: relative
}

.leftHeaderlist li a {
    color: var(--color-white);
}

.fixed-head .leftHeaderlist li a {
    color: var(--color-dark);
}

/*==========================================================*/
.header-search {
    position: relative;
}

.header-search input {
    width: 200px;
    height: 38px;
    border-radius: 50px;
    border: 1px solid var(--color-gray-200);
    background: transparent;
    padding: 0px 15px;
    color: var(--color-white);
}

.searchbutton {
    position: absolute;
    background: transparent;
    border: 0;
    right: 10px;
    font-size: 20px;
    top: 2px;
    color: var(--color-white);
}

/*==========================================================*/
.dropdown-toggle::after {
    border: 0px !important;
}

.dropdown-hover:hover>.dropdown-menu {
    display: inline-block;
}

.dropdown-hover>.dropdown-toggle:active {
    pointer-events: none;
}

.submenu {
    border: 0px !important;
    background: var(--color-white) !important;
    margin: 0 !important;
    min-width: 15rem !important;
    padding: 0 !important;
    border-radius: 0px !important;
}

.submenu .dropdown-item {
    display: block;
    width: 100%;
    padding: 7px 15px;
    clear: both;
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 1rem;
}

.submenu .dropdown-item:hover {
    background: var(--color-white) !important;
    color: var(--color-primary) !important;
}

.mega-dropdown {
    position: static !important;
}

.mega-menu {
    width: 100%;
    left: 0;
    border: 0px !important;
    margin: 0 !important;
    padding: 25px 0px !important;
    background: var(--color-white) !important;
    border-radius: 0px !important;
    border-top: 1px dashed var(--color-gray-200) !important;
}

.mega-menu .container {
    display: block !important;
}

.mega-menu-imgbox {
    width: 100%;
    position: relative;
}

.mega-menu-imgbox img {
    width: 100%;
}

.mega-menu-imgbox-content {
    padding: 10px 0px;
    width: 100%;
}

.menuiMG {
    width: 100%;
    position: relative;
    text-align: center;
}

.menuiMG .menuiMGMedia {
    position: relative;
    margin-bottom: 0;
    transition: box-shadow 0.3s;
    width: 100%;
    height: 300px;
    text-align: center;
}

.menuiMG img {
    display: block;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    object-fit: cover;
}

.menuiMG .menuiMGContent {
    position: relative;
    background-color: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.menuiMGContent a {
    display: block;
    color: var(--color-dark);
}

.menuiMGContent h2 {
    font-size: 16px;
}

.mlist {
    width: 100%;
    position: relative;
}

.mlist ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.mlist ul li {
    display: inline-block;
    width: 100%;
    position: relative;
}

.mlist ul li a {
    color: var(--color-dark);
    display: inline-block;
    line-height: 30px;
    font-size: 0.9rem;
}

.mlist ul li a:hover {
    color: var(--color-secondary);
}

.mlist .title {
    color: var(--color-dark);
}

.mlist .title a {
    color: var(--color-dark);
}

/*==========================================================*/
.menuModal.modal.left .modal-dialog {
    position: fixed;
    margin: auto;
    width: 350px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.menuModal.modal.left.fade .modal-dialog {
    left: -350px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.menuModal.modal.left.fade.show .modal-dialog {
    left: 0;
}

.menuModal.modal.left .modal-content {
    height: 100%;
    overflow-y: auto;
}

.menuModal .modal-content {
    background: var(--color-white);
    border: 0;
    border-radius: 0;
}

.msprofile {
    width: 100%;
    position: relative;
    padding: 10px 15px;
    border-bottom: 1px solid var(--color-gray-200);
}

.msprofile .msprofile-media {
    width: 60px;
    height: 60px;
    overflow: hidden;
    background: var(--color-gray-100);
    border-radius: 50px;
}

.msprofile .msprofile-media img {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

.msprofile .msprofile-content {
    flex: 1;
    padding-left: 15px;
}

.msprofile-arrow {
    margin-left: auto;
}

.sidemenu {
    width: 100%;
    position: relative;
    padding-top: 15px;
}

.sidemenu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.sidemenu ul li {
    display: inline-block;
    width: 100%;
    position: relative;
    font-size: 16px;
}

.sidemenu ul li .sub-menu-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
}

.sidemenu ul li .sub-menu-inner img {
    height: auto;
}

.sub-menu-inner .right {
    width: 50px;
    text-align: right;
}

.sidemenu ul li a {
    -webkit-transition: 0.2s linear;
    -moz-transition: 0.2s linear;
    -ms-transition: 0.2s linear;
    -o-transition: 0.2s linear;
    transition: 0.2s linear;
}

.sidemenu ul li ul {
    background: var(--color-gray-100);
}

.sidemenu ul li ul .menu_color {
    background: var(--color-gray-300);
}

.sidemenu ul li ul li a {
    display: flex;
    align-items: center;
    font-size: 16px;
    width: 100%;
    position: relative;
}

/*==========================================================*/
.homeslider {
    width: 100%;
    position: relative;
    height: 100%;
}

.homeslider .homesliderMedia {
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.homeslider .homesliderMedia img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.homesliderContent {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--color-white);
    text-align: center;
    padding-top: 100px;
}

.homeslider .swiper-arrow {
    position: absolute;
    top: 45%;
    left: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
}

.homeslider .swiper-arrow .prev-arrow {
    left: 15px;
    font-size: 52px;
    color: var(--color-white);
    position: absolute;
    opacity: 0.9;
}

.homeslider .swiper-arrow .next-arrow {
    right: 15px;
    font-size: 52px;
    color: var(--color-white);
    position: absolute;
    opacity: 0.3;
}

.homeslider .swiper-arrow .next-arrow:hover,
.homeslider .swiper-arrow .prev-arrow:hover {
    opacity: 1;
    cursor: pointer;
}

@media only screen and (max-width:968px) {
    .homeslider .homesliderMedia {
        width: 100%;
        height: 70vh;
        overflow: hidden;
    }

    .homesliderContent {
        padding-top: 0px;
    }
}

/*==========================================================*/
.footer {
    width: 100%;
    position: relative;
}

.footer .footer-top {
    border-bottom: 1px solid var(--color-gray-200);
    border-top: 1px solid var(--color-gray-200);
    width: 100%;
    position: relative;
    padding: 30px 0px;
}

.footer .footer-middle {
    border-bottom: 1px solid var(--color-gray-200);
    width: 100%;
    position: relative;
    padding: 30px 0px;
}

.footer .footer-bottom {
    width: 100%;
    position: relative;
    padding: 30px 0px;
}

.footer .footer-logo {
    display: inline-block;
}

.footer .footer-logo img {
    width: 150px;
}

@media only screen and (max-width:968px) {
    .footer .footer-middle {
        padding: 0px;
    }

    .footer-middle .accordion {
        border-radius: 0px !important;
    }

    .footer-middle .accordion-item {
        border-radius: 0px;
    }

    .footer-middle .accordion-button {
        border-radius: 0px !important;
    }
}

.icbbox {
    width: 100%;
    position: relative;
    display: flex;
    gap: 15px
}

.icbbox .icbbox-icon {
    width: 32px;
    padding-top: 5px;
}

.icbbox .icbbox-content {
    flex: 1;
}

.icbbox .icbbox-content h5 {
    font-size: 18px;
}

@media only screen and (max-width:968px) {
    .icbbox {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 15px;
        border-bottom: 1px dotted var(--color-gray-300);
        padding-bottom: 15px;
        margin-bottom: 15px;
    }
}

.footer-social {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 10px;
}

.footer-social li {
    display: inline-block;
}

.footer-social li a {
    display: inline-block;
    color: var(--color-dark);
    font-size: 20px;
}

@media only screen and (max-width:968px) {
    .footer-social {
        justify-content: center;
    }
}

.footer-title {
    font-size: 18px;
    margin-bottom: 20px;
    display: inline-block;
    width: 100%;
}

.footerList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.footerList li {
    display: inline-block;
    line-height: 28px;
    width: 100%;
    position: relative;
    font-size: 14px;
}

.footerList li a {
    color: var(--color-dark);
}

.copyright {
    width: 100%;
    position: relative;
    padding: 10px 0px;
    text-align: center;
}

/*==========================================================*/
.product {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.product .product-media-shap {
    display: block;
    overflow: hidden;
    position: relative;
}

.product .product-media-shap::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.product.product-style-2 .product-media-shap::before {
    content: "";
    display: block;
    padding-top: 130%;
}

.product .product-media {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: box-shadow .3s;
    width: 100%;
}

.product .product-media:hover img:first-child {
    opacity: 0
}

.product .product-media:hover img:last-child {
    opacity: 1
}

.product .product-media img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    transition: opacity .3s ease-in;
}

.product .product-media img:last-child {
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    left: 0;
    object-fit: cover;
}

.product .product-media img:first-child {
    position: relative;
    opacity: 1
}

.product .product-details {
    position: relative;
    padding-top: 1rem;
    padding-bottom: 0rem;
}

.text-center .product-details {
    padding-left: 1rem;
    padding-right: 1rem;
}

.product .product-details .product-name {
    margin-bottom: 0.5rem;
    font-size: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 24px;
}

.product .product-details .product-name a {
    display: inline-block;
    color: var(--color-dark);
    width: 100%;
}

/* .product-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    font-size: 14px;
    gap: 10px;
    font-weight: 500;
    color: var(--color-gray-500); 
} */
.product-price {
    font-size: 14px;
    font-weight: 500;
    color: var(--color-gray-500);
    min-width: 90px;
    display: flex;
    justify-content: end;
}

.product-price .new-price {
    text-decoration: none;
}

.product-price .old-price {
    color: #aaa;
}

.product.text-center .product-price {
    justify-content: center;
}

.product-action-vertical {
    position: absolute;
    top: 10px;
    right: 10px;
    transition: opacity 0.3s, visibility 0.3s;
}

.btn-product-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-bottom: 0.5rem;
    border: 0px solid #e1e1e1;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.5);
    color: #999;
    font-size: 16px;
    font-weight: 700;
    transition: border-color 0.3s, color 0.3s, background-color 0.3s
}

.btn-product-icon:hover {
    border-color: var(--color-secondary);
    color: #fff !important;
    background-color: var(--color-secondary)
}

.product-action-vertical i {
    font-size: 1.2rem;
    margin-top: 3px;
}

.product-label {
    position: absolute;
    left: 10px;
    top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.product-label span {
    padding: 5px 15px 1px;
    background: var(--color-white);
    border-radius: 20px;
    display: inline-block;
    font-size: 14px;
}

.product-label .label-new {
    background: var(--color-white);
}

.product-label .label-sale {
    background: var(--color-primary)
}

.product-label .label-top {
    background: var(--color-secondary)
}

.product-label .label-stock {
    background: #999
}

/*==========================================================*/
.product-gallery {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    position: sticky;
}

.galerySwiper,
.thumbSwiper {
    flex-basis: 100%;
    max-width: 100%;
}

.product-gallery .galerySwiper {
    max-width: calc(100% - 119px);
}

.product-gallery .thumbSwiper {
    order: -1;
    max-width: 109px;
}

.product-gallery .product-image {
    display: block;
    overflow: hidden;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-gallery .product-image::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.product-gallery .product-image .product-image-item {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background: var(--color-gray-100);
}

.product-gallery .product-image .product-image-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s;
    mix-blend-mode: darken;
}

.thumbSwiper .swiper-slide {
    position: relative;
}

.thumbSwiper .swiper-slide::before {
    content: "";
    transition: border-color 0.3s;
    border: 1px solid transparent;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9;
}

.thumbSwiper .swiper-slide.swiper-slide-thumb-active::before {
    border-color: var(--color-primary);
}

@media only screen and (max-width:968px) {
    .product-gallery {
        position: relative;
        display: block;
    }
}

/*==========================================================*/
.single-product .product-details .title {
    display: flex;
    gap: 30px;
    justify-content: space-between;
}

.single-product .product-details .title h1 {
    font-size: 24px;
    font-weight: 600 !important;
}

.btn-wishlist {
    background: transparent;
    padding: 0px;
    border: 0px;
    font-size: 24px;
}

.single-product .product-price {
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 0px;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
}

.single-product .product-price .new-price {
    text-decoration: none;
    font-weight: 600;
}

.single-product .product-price .old-price {
    color: #aaa;
    font-size: 16px;
}

.productVaration {
    width: 100%;
    position: relative;
    margin-top: 10px;
}

.productVaration .productVarationName {
    margin-bottom: 5px;
}

.productVarationMeta {
    display: flex;
    align-items: center;
    gap: 10px;
}

.productVarationMeta .color {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    transition: 0.3s;
}

.productVarationMeta .color.active {
    box-shadow: inset 0 0 0 2px #fff;
    border: 1px solid var(--color-primary);
}

.productVarationMeta .color:hover {
    box-shadow: inset 0 0 0 2px #fff;
    border: 1px solid var(--color-primary);
}

.productVarationMeta .text {
    align-items: center;
    border: 1px solid var(--color-gray-300);
    border-radius: 2px;
    display: flex;
    height: auto;
    justify-content: center;
    transition: .3s;
    width: auto;
    padding: 7px 10px 2px;
}

.productVarationMeta .text.active {
    box-shadow: inset 0 0 0 2px #fff;
    border: 1px solid var(--color-primary);
}

.productVarationMeta .text:hover {
    box-shadow: inset 0 0 0 2px #fff;
    border: 1px solid var(--color-primary);
}

.productVarationMeta .swatch {
    width: 50px;
    height: 50px;
    border-radius: 2px;
    transition: 0.3s;
    border: 1px solid var(--color-gray-300);
    display: flex;
    align-items: center;
    justify-content: center;
}

.productVarationMeta .swatch img {
    width: 40px;
    height: 40px;
}

.productVarationMeta .swatch.active {
    box-shadow: inset 0 0 0 2px #fff;
    border: 1px solid var(--color-primary);
}

.productVarationMeta .swatch:hover {
    box-shadow: inset 0 0 0 2px #fff;
    border: 1px solid var(--color-primary);
}

.product-footer {
    display: flex;
    align-items: center;
    gap: 15px;
}

.pqty {
    align-content: center;
    border: 1px solid var(--color-gray-200);
    display: flex;
    height: 55px;
    justify-content: space-between;
    width: 120px;
    font-size: 18px;
    cursor: pointer;
}

.pqty span {
    align-items: center;
    display: flex;
    height: 55px;
    justify-content: center;
    width: 100%;
}

.product-footer-bottom {
    width: 100%;
    position: relative;
}

.product-footer-bottom ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.product-footer-bottom ul li {
    cursor: pointer;
    border-bottom: 1px solid var(--color-gray-700);
}

/*==========================================================*/
.pshippingbox {
    padding-bottom: 20px;
    border-bottom: 1px solid var(--color-gray-300);
    margin-bottom: 20px;
}

/*==========================================================*/
.catSection {
    background: var(--color-white);
}

.categoriesbox {
    width: 100%;
    position: relative;
    padding: 50px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.categoriesbox .categoriesbox-media {
    position: relative;
    border: 6px solid var(--color-gray-100);
    box-sizing: border-box;
    margin: 75px;
}

.categoriesbox-media img {
    width: 100%;
}

.categoriesbox .title {
    font-size: 24px;
}

.learnmorebtn {
    text-transform: uppercase;
    color: var(--color-dark);
    position: relative;
    display: inline-block;
    padding: 10px 0px;
}

.learnmorebtn::after {
    content: '';
    background: var(--color-dark);
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    height: 2px;
}

/*==========================================================*/
.subheader {
    width: 100%;
    position: relative;
    padding: 20px 0px;
}

.subheader .breadcrumb-item a {
    color: var(--color-dark);
}

/*==========================================================*/
.blog-list {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
}

.blog-list .blog-list-media {
    width: 100%;
    height: 300px;
    overflow: hidden;
}

.blog-list .blog-list-media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.blog-list .blog-list-content {
    width: 100%;
    position: relative;
}

.blog-list .blog-list-content .title {
    text-transform: capitalize;
    font-size: 24px;
}

.readarticlebutton {
    text-decoration: underline;
    color: var(--color-dark);
}

.readarticlebutton:hover {
    text-decoration: none;
    color: var(--color-primary);
}

/*==========================================================*/
.roomeditcolm {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: nowrap;
    position: relative;
    overflow: hidden;
    height: 445px;
    width: 100%;
}

.roomeditcolm .roomeditcolmtoph {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--color-primary);
    justify-content: space-between;
}

.roomeditcolm .roomeditcolmtoph>h2 {
    padding-left: 79px;
}

.roomeditcolm .roomeditcolmtoph+h2 {
    width: 455px;
    left: -346px;
    text-align: right;
    padding-right: 79px;
    transform-origin: 400.5px 100%;
    transform: rotate(-90deg);
    padding-bottom: 10px;
    border-bottom: 1px solid var(--color-primary);
    box-sizing: border-box;
    position: absolute;
    top: 0;
}

.roomeditcolm>p {
    font-size: 20px;
    width: 62%;
    margin-right: 8%;
    margin-bottom: 15%;
    line-height: 32px;
}

.roomgallery {
    margin-top: 47px;
}

.roomshareicon {
    color: var(--color-primary);
}

.roomgallery img {
    width: 100%;
}

/*==========================================================*/
.loginbox {
    width: 100%;
    position: relative;
    padding: 35px;
    background: var(--color-light-100);
}

/*==========================================================*/
.contactbox {
    width: 100%;
    min-height: 100%;
    border: 1px solid var(--color-gray-200);
    padding: 32px;
    margin-top: 30px;
}

/*==========================================================*/
.form-floating input {
    border-radius: 0px;
}

.form-group {
    width: 100%;
    position: relative;
}

.form-group label {
    margin-bottom: 0.5rem;
    width: 100%;
    position: relative;
    font-size: 14px;
    font-weight: 600;
}

.form-group input {
    width: 100%;
    height: 50px;
    border: 1px solid var(--color-gray-200);
    padding: 0px 15px;
}

.form-group select {
    width: 100%;
    height: 50px;
    border: 1px solid var(--color-gray-200);
    padding: 0px 15px;
    background: var(--color-white);
}

.form-group textarea {
    width: 100%;
    height: 110px;
    border: 1px solid var(--color-gray-200);
    padding: 0px 15px;
}

/*==========================================================*/
.listing-filter {
    width: 100%;
    position: relative;
}

.listing-filter .listing-filter-top {
    border-bottom: 1px solid var(--color-gray-200);
    padding: 25px 0px;
}

.listing-filter .listing-filter-bottom {
    padding: 25px 0px;
}

.filter-form {
    width: 100%;
    position: relative;
}

.filter-form label {
    width: 100%;
    margin-bottom: 0.5rem;
    font-size: 14px;
}

.filter-form .filter-form-select {
    position: relative;
}

.filter-form select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 28px;
    padding: 0;
    -webkit-appearance: menulist-button;
    -moz-appearance: none;
    opacity: 0;
}

.sortby {
    display: flex;
    align-items: center;
    gap: 5px;
}

.sortby select {
    background: var(--color-white);
    border: 0px;
}

.vpagination {
    margin: 0;
    padding: 0;
    list-style: none;
    gap: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.vpagination li {
    display: inline-block;
}

.vpagination li a {
    color: var(--color-gray-500);
}

.vpagination li a.active {
    color: var(--color-dark);
    font-weight: 600;
}

/*==========================================================*/
.about-img {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    gap: 120px;
}

.about-img-second {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 150px;
}

.about-img .about-img-first {
    width: 348px;
}

.about-img .about-img-second {
    width: 462px;
}

/*==========================================================*/
.section-book {
    width: 100%;
    position: relative;
}

.section-book button {
    width: 100%;
    position: relative;
    transition: all .8s cubic-bezier(.215, .61, .355, 1);
    color: var(--color-primary);
    text-transform: uppercase;
    font-size: 2.25rem;
    line-height: 2.5rem;
    font-family: var(--font-secondary);
    background: transparent;
    border: 0px;
}

.button-border-top,
.button-border-bottom {
    position: absolute;
    transition: all .8s cubic-bezier(.215, .61, .355, 1);
    background: var(--color-primary);
    opacity: 1;
    height: 1px;
    width: 100%;
    left: 0;
    right: 0;
}

.button-border-top {
    top: 0;
}

.button-border-bottom {
    bottom: 0;
}

.section-book button .button-text {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button-text span {
    transition: all .8s cubic-bezier(.215, .61, .355, 1);
}

/*==========================================================*/
.tradebox {
    width: 100%;
    position: relative;
}

.tradebox .tradebox-inner {
    background: var(--color-white);
    padding: 50px;
    border-top: 5px solid var(--color-secondary);
    border-bottom: 5px solid var(--color-secondary);
    border-radius: 10px;
}

/*==========================================================*/
.bookapponimentform {
    border-left: 1px solid var(--color-gray-200);
    padding-left: 50px;
}

/*==========================================================*/
.cartModal.modal.left .modal-dialog,
.cartModal.modal.right .modal-dialog {
    position: fixed;
    margin: auto;
    width: 420px;
    height: 100%;
    -webkit-transform: translate3d(0%, 0, 0);
    -ms-transform: translate3d(0%, 0, 0);
    -o-transform: translate3d(0%, 0, 0);
    transform: translate3d(0%, 0, 0);
}

.cartModal.modal.left .modal-content,
.cartModal.modal.right .modal-content {
    height: 100%;
    overflow-y: auto;
}

.cartModal.modal.right.fade .modal-dialog {
    right: -420px;
    -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
    -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
    -o-transition: opacity 0.3s linear, right 0.3s ease-out;
    transition: opacity 0.3s linear, right 0.3s ease-out;
}

.cartModal.modal.right.fade.show .modal-dialog {
    right: 0;
}

@media (max-width: 991px) {

    .cartModal.modal.left .modal-dialog,
    .cartModal.modal.right .modal-dialog {
        position: fixed;
        margin: auto;
        width: 350px;
        height: 100%;
        -webkit-transform: translate3d(0%, 0, 0);
        -ms-transform: translate3d(0%, 0, 0);
        -o-transform: translate3d(0%, 0, 0);
        transform: translate3d(0%, 0, 0);
    }

    .cartModal.modal.left .modal-content,
    .cartModal.modal.right .modal-content {
        height: 100%;
        overflow-y: auto;
    }

    .cartModal.modal.right.fade .modal-dialog {
        right: -350px;
        -webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
        -moz-transition: opacity 0.3s linear, right 0.3s ease-out;
        -o-transition: opacity 0.3s linear, right 0.3s ease-out;
        transition: opacity 0.3s linear, right 0.3s ease-out;
    }

    .cartModal.modal.right.fade.show .modal-dialog {
        right: 0;
    }
}

/*==========================================================*/
.cartModal .modal-content {
    background: #fff;
    background: var(--color-white);
    border: 0;
    border-radius: 0;
}

.cartModal .modal-header {
    padding: 15px 15px;
    border-bottom: 1px dotted var(--color-gray-200);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cartModal .modal-header .modal-title {
    font-size: 18px;
}

.cartModal .modal-close {
    background: transparent;
    border: 0px;
    font-size: 24px;
}

.cartModal .modal-footer {
    border: 0px !important;
    justify-content: center;
    padding: 15px 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.cartbox-total {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    font-weight: 600;
    border-bottom: 1px solid var(--color-gray-200);
    border-top: 1px solid var(--color-gray-200);
    font-size: 16px;
    width: 100%;
}

.cartbox-action-btn {
    border-bottom: 2px solid var(--color-primary);
    display: inline-block;
    margin-bottom: 20px;
    color: var(--color-dark);
}

/*==========================================================*/
.rfm-child {
    width: 200px;
}

/*==========================================================*/
.featuredcatbox {
    position: relative;
    overflow: hidden;
    width: 100%;
    text-align: center;
}

.featuredcatbox .featuredcatbox-media {
    display: block;
    overflow: hidden;
    position: relative;
}

.featuredcatbox .featuredcatbox-media::before {
    content: "";
    display: block;
    padding-top: 100%;
}

.featuredcatbox .featuredcatbox-media figure {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: box-shadow .3s;
    width: 100%;
}

.featuredcatbox .featuredcatbox-media img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    transition: opacity .3s ease-in;
}

.featuredcatbox .featuredcatbox-content {
    padding-top: 10px;
}

.featuredcatboxtitle {
    font-size: 18px;
}

.featuredcatboxtitle a {
    color: var(--color-dark);
    display: inline-block;
}

.featuredcatbox.category-style-2 {
    text-align: left;
}

.featuredcatbox.category-style-2 .featuredcatbox-media::before {
    padding-top: 150%;
}

/*==========================================================*/
.cartsection {
    width: 100%;
    min-height: 100vh;
    background: var(--color-gray-100);
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.cartsection .cartsection-left {
    background: var(--color-white);
    padding: 50px;
}

.cartsection .cartsection-right {
    padding: 50px;
}

.cartproductbox {
    width: 100%;
    position: relative;
    padding: 25px 0px;
    border-bottom: 1px solid var(--color-gray-200);
    border-top: 1px solid var(--color-gray-200);
}

.cartproductbox .item-image {
    display: flex;
    justify-content: space-between;
    width: 125px;
    height: auto;
    margin-right: 20px;
    text-align: center;
}

.cartproductbox .item-image img {
    width: 100%;
    object-fit: cover;
}

.cartproductbox .item-attributes {
    width: 100%;
    position: relative;
}

.cartproductbox .item-attributes .title {
    font-size: 16px;
}

.cartproductbox .cartPrice {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    font-size: 20px;
    font-weight: 600;
    flex-direction: column;
}

.cartproductbox .cartPrice .new-price {
    text-decoration: none;
}

.cartproductbox .cartPrice .old-price {
    color: #aaa;
    font-size: 16px;
}

.cartproductbox .qqty {
    display: flex;
    align-items: center;
    gap: 20px;
}

.cartproductbox .qqty .qqa {
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--color-gray-500);
    border-radius: 50%;
}

.cartproductbox-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
    gap: 20px;
    font-size: 12px;
}

.cartproductbox-footer a {
    display: flex;
    align-items: center;
    gap: 5px;
}

.cartpricebox {
    margin-top: 30px;
}

.cartpricebox ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.cartpricebox ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 7px 0px;
}

.cspaceProduct {
    background: var(--color-white);
    padding: 15px;
    position: relative;
    width: 100%;
    display: flex;
    gap: 15px;
    margin-top: 15px;
}

.cspaceProduct .cspaceProductMedia {
    width: 65px;
    height: 65px;
    overflow: hidden;
}

.cspaceProductMedia img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cspaceProduct .cspaceProductContent {
    flex: 1;
}

.cspaceProductContent .title {
    font-size: 16px;
}

.cspace-price {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    font-size: 14px;
    gap: 5px;
    font-weight: 700;
    color: var(--color-gray-500);
}

.cspace-price .new-price {
    text-decoration: none;
}

.cspace-price .old-price {
    color: #aaa;
}

/*==========================================================*/
.newsletter {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    gap: 5px;
}

.newsletter input {
    width: 100%;
    height: 40px;
    border: 1px solid var(--color-gray-200);
    font-size: 14px;
    padding: 0px 10px;
}

.newsletter .subscribebutton {
    background: var(--color-dark);
    border: 1px solid var(--color-dark);
    height: 40px;
    color: var(--color-white);
    font-size: 14px;
    padding: 0px 15px;
}

/*==========================================================*/
.faqsection {
    width: 100%;
    min-height: 100vh;
    background: var(--color-white);
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.faqsection .faqsection-left {
    background: var(--color-gray-100);
    padding: 50px;
}

.faqsection .faqsection-right {
    padding: 50px;
}

.faqlist {
    width: 100%;
    position: relative;
}

.faqlist ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

.faqlist ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    line-height: 36px;
    cursor: pointer;
}

/*==========================================================*/
.newsletteModal .modal-dialog {
    max-width: 800px;
}

.newsletteModal .modal-content {
    border-radius: 0px;
    border: 0px;
    background: transparent;
}

.newsletteModal .newsletteModalContent {
    border-radius: 20px;
    background: var(--color-white);
    width: 100%;
    position: relative;
    overflow: hidden;
}

.newsletteModal-Close {
    position: absolute;
    top: 15px;
    right: 15px;
    background: transparent;
    border: 0px;
    font-size: 22px;
}

.newsletteModalC {
    padding: 35px;
}

/*==========================================================*/
.ordersection {
    background-position: 100% !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    padding: 100px 0;
}

.trakorderbox {
    width: 900px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    gap: 10px;
}

.trakorderbox input {
    width: 100%;
    height: 50px;
    border: 1px solid var(--color-dark);
    background: transparent;
    padding: 15px;
    color: var(--color-white);
}

@media only screen and (max-width: 968px) {
    .trakorderbox {
        flex-direction: column;
        width: 100%;
        padding: 25px;
    }
}

/*==========================================================*/
.modalSearchbox {
    padding: 15px;
    width: 100%;
    position: relative;
}

.modalSearchbox input {
    width: 100%;
    height: 50px;
    border: 1px solid var(--color-gray-200);
    border-radius: 10px;
    padding: 15px 15px 15px 55px;
}

.modalSearchboxIcon {
    position: absolute;
    left: 30px;
    font-size: 24px;
    top: 21px;
}

.searchList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.searchList li {
    line-height: 30px;
}

/*==========================================================*/
.orderinfo .accordion-button {
    border-top: .0625rem solid rgba(36, 34, 34, .15);
    padding: 2rem 0rem;
    font-size: 1.5rem;
}

.orderinfo .accordion-item:last-child {
    border-bottom: .0625rem solid rgba(36, 34, 34, .15) !important;
}

.orderinfo .accordion-item {
    border: none !important;
}

.orderinfo>h2 {
    margin-bottom: 40px;
}

.orderinfo .accordion-item:first-of-type>.accordion-header .accordion-button {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.orderinfo .accordion-button:not(.collapsed) {
    background-color: transparent;
    box-shadow: none;
}

.orderinfo .accordion-body {
    padding: 1rem 0;
}

.orderinfo .accordion-body p {
    font-size: .75rem;
    margin-bottom: 10px;
}

.orderinfo .accordion-button:focus {
    box-shadow: none;
}

.orderinfo .accordion-button::after {
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAN9JREFUaEPtmDEOwkAMBCcdNfA0Suh5Ej2UPI1Q04GQTlTRYXmJkGHT5uw7744TJwPFr6H4+XEB33bQDtgBUQEj1BFwAyza/RtwFsWeDJ/TgQuwaruOwNoFTChgBzpYGKFIzxghIxThpLPGCBkhIyQqUAGh51R5SNS5hNc39x24JnLs302xkafQFjgmNv9EyA449RL9RQHlEcqi4Gk0olykByJ5ptbYgYhydqDCmzjipHsgq5J7wD2QZafFGaFfRqj873WR7lj4nD0QO4G4ygWIAsrhdkCWUExgB0QB5fAHcJdEMcy+90cAAAAASUVORK5CYII=);
}

.orderinfo .accordion-button:not(.collapsed)::after {
    transform: rotate(-135deg);
}

.cartsection .btn:hover {
    background-color: var(--color-dark);
    border-color: var(--color-dark);
}

/*==========================================================*/
.checkour-container {
    background: #efefef;
}

header.checkout-header {
    display: flex;
    /*box-shadow: 0 6px 15px 0 #0000000d;*/
    width: 100%;
    margin: 0 auto;
    gap: 50px;
    padding: 20px 10%;
    align-items: center;
}

.checkout-wapper {
    display: flex;
    /* box-shadow: 0 6px 15px 0 #0000000d; */
    width: 100%;
    margin: 20px auto 0;
    gap: 50px;
    padding: 10px 10%;
    align-items: flex-start;
}

.checkout-wapper .text-underlined {
    text-decoration: underline;
    cursor: pointer;
}

.checkout-wapper .left {
    width: 65%;
    padding: 20px;
    background: #fff;
}

.checkout-wapper .right {
    width: 35%;
}

.checkout-wapper .right img {
    width: 100%;
}

.checkoutProduct {
    display: flex;
    gap: 10px;
    margin-bottom: 40px;
}

.checkoutProductFooter {
    display: flex;
    justify-content: space-between;
}

.checkoutProductMedia {
    width: 20%;
}

.checkoutProductContent .title a {
    font-size: 20px;
    line-height: 25px;
}


ul.checkputPricebox {
    display: flex;
    flex-direction: column;
    padding: 0px;
    list-style: none;
    gap: 10px;
}

ul.checkputPricebox li {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

ul.checkputPricebox li span {
    font-weight: bold;
    text-transform: uppercase;
    font-size: 14px;
}

ul.checkputPricebox li:last-child {
    border-top: solid 1px #c5c5c5;
}

.checkoutProductContent {
    width: 80%;
}

.checkoutProductqty {
    display: flex;
    gap: 5px;
}

.checkoutProductqty span {
    border: solid 1px #c5c5c5;
    min-width: 10px;
    width: 25px;
    text-align: center;
}


.flotting-group label {
    margin-left: 12px;
    margin-bottom: 10px;
    font-family: var(--font-secondary) !important;
}

.flotting-group input,
.flotting-group select {
    border-radius: 50px;
}


header.checkout-header .checkoutLogo img {
    width: 80px;
}

ul.checkoutStep {
    width: 50%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    list-style: none;
    border-top: solid 2px #bcbcbc;
    padding: 10px 0 0 0;
}

ul.checkoutStep li {
    width: 50%;
    position: relative;
    text-align: left;
}

ul.checkoutStep li a {
    font-size: 16px !important;
}

ul.checkoutStep li.active::before {
    content: "";
    position: absolute;
    width: 100%;
    border: solid 3px #000;
    top: -13px;
}

.addressModal-content {
    padding: 20px;
}

.addressModal-Close {
    position: absolute;
    right: 5px;
    border: solid 1px;
    background: transparent;
    top: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.cartAddressbox {
    border: solid 1px;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    cursor: pointer;
}

.cartAddressbox .radioBox {
    position: absolute;
    right: 5px;
    top: 5px;
}

.tx-14 {
    font-family: var(--font-secondary) !important;
}

.checkoutAddress h6 {
    font-size: 16px;
}

.buttonLoader {
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cartSectionTitle {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

a.changelink {
    font-size: 14px;
    text-decoration: underline;
}

.paymentoptionbox {
    padding: 10px;
    display: block;
    border-top: solid 1px;
    border-bottom: solid 1px;
    cursor: pointer;
}

.paymentoptionbox-header {
    display: flex;
    justify-content: space-between;
}

.paymentoptionimg {
    display: none;
    gap: 10px;
}

.paynow {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginBtnCheckout {
    margin-top: 30px;
    display: flex;
    justify-content: center;
    padding: 8px 40px;
}

/*==========================================================*/
.thankusection {
    width: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    height: 100%;
    left: 0;
    top: 0;
}

.thankusection .thankusection-left {
    width: 65%;
    position: relative;
    height: 100%;
    padding: 50px 10px 50px 100px;
}

.thankusection .thankusection-right {
    width: 35%;
    position: relative;
    height: 100%;
    background: var(--color-black-light);
    padding: 50px 30px;
}

.tsleft-header {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.tsleft-header-icon {
    width: 65px;
    height: 65px;
}

.tsleft-header-icon img {
    width: 65px;
    height: 65px;
    border-radius: 50%;
}

.tsleft-header-text {
    flex: 1;
    padding-left: 15px;
}

.tsleft-box {
    width: 100%;
    position: relative;
    border-radius: 5px;
    background: var(--color-light02);
}

.tsleft-box .tsleft-box-header {
    padding: 15px;
    border-bottom: 1px solid var(--color-black-light-02);
}

.tsleft-box .tsleft-box-body {
    padding: 15px;
}

.thankusection-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

@media (max-width: 991px) {
    .thankusection {
        flex-direction: column;
        padding: 15px;
    }

    .thankusection .thankusection-left {
        width: 100%;
        position: relative;
        height: auto;
        padding: 0;
    }

    .thankusection .thankusection-right {
        width: 100%;
        position: relative;
        height: auto;
        padding: 0;
        background: var(--color-black);
        margin-top: 0px;
    }

    .thankusection .cartsec {
        padding: 10px 0px;
        border-bottom: 1px solid var(--color-black-light-02);
    }

    .thankusection-footer {
        flex-direction: column;
    }

    .tsleft-header {
        width: 100%;
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }

    .tsleft-header-text {
        flex: 1;
        padding-left: 0px;
        margin-top: 20px;
    }
}


.cartProductInner img {
    width: 100px;
}

.cartProductInner {
    display: flex;
    gap: 20px;
}

.cartPrice ul li {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cartPrice ul {
    padding: 0px;
    list-style: none;
}

.cartProductDetails {
    width: 100%;
}

.thankusection-footer {
    border-top: solid 1px #000;
    border-bottom: solid 1px #000;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-bottom: 50px !important;
}

.thankusection-footer a.btn.btn-primary-outline.btn-medium {
    background: #000;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
}

.tsleft-box .tsleft-box-header {
    border-bottom: #000 solid 1px;
    padding: 15px 0;
}

.tsleft-box .tsleft-box-body {
    padding: 15px 0;
}

.thankusection-footer a {
    text-decoration: underline;
}

/*==================================================================*/

.wish-addtocart {
    background-color: #131313;
    color: #fff;
    padding: 5px 20px;
    border-radius: 50px;
    font-size: 14px;
    font-weight: 400;
}

.wish-remove {
    position: absolute;
    z-index: 999;
    right: 10px;
    top: 10px;
    font-size: 24px;
    cursor: pointer;
}

.sub-details {
    display: flex;
    justify-content: space-between;
}

.wishlist .product-media::after {
    background: linear-gradient(180deg, rgba(255, 255, 255, .6) 0%, rgba(255, 255, 255, 0) 20%) !important;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

/*==========================================================*/
section.help {
    background-color: #242222;
}

.help h2 {
    color: #fff;
    font-size: 40px;
}

.help .text-center p {
    color: var(--color-gray-500);
    margin-bottom: 0px;
    font-size: 14px;
}

.help-footer {
    margin-top: 40px;
}

.help-footer ul {
    width: 100%;
    display: flex;
    gap: 4%;
    padding: 0;
}

.help-footer ul li {
    width: 20%;
}

.help-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: .2s ease;
    min-height: 300px;
}



.help-box figure {
    max-height: 200px;
    overflow: hidden;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: end;
    width: 100%;
    transition: .2s ease;
}

.help-box:hover figure {
    display: none;
}



.help-box figure img {
    width: 100%;
    zoom: 10%;
}

.help-footer h4 {
    color: #fff;
    font-size: 24px;

}

.help-footer .circle {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    color: #fff;
    border: solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 10px;
}

/*==========================================================*/




/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
@font-face {
    font-family: "riode";
    src: url("/public/fonts/riode.eot");
    src: url("/public/fonts/riode.eot") format("embedded-opentype"),
        url("/public/fonts/riode.ttf") format("truetype"),
        url("/public/fonts/riode.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: block
}

[class*=" d-icon-"],
[class^=d-icon-] {
    display: inline-block;
    line-height: 1
}

[class*=" d-icon-"]:before,
[class^=d-icon-]:before {
    font-family: "riode" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.d-icon-truck:before {
    content: ""
}

.d-icon-service:before {
    content: ""
}

.d-icon-secure:before {
    content: ""
}

.d-icon-lock:before {
    content: ""
}

.d-icon-percent:before {
    content: ""
}

.d-icon-layer:before {
    content: ""
}

.d-icon-alert:before {
    content: ""
}

.d-icon-database:before {
    content: ""
}

.d-icon-money:before {
    content: ""
}

.d-icon-shoppingbag:before {
    content: ""
}

.d-icon-t-shirt1:before {
    content: ""
}

.d-icon-t-shirt2:before {
    content: ""
}

.d-icon-hat:before {
    content: ""
}

.d-icon-officebag:before {
    content: ""
}

.d-icon-handbag:before {
    content: ""
}

.d-icon-backpack:before {
    content: ""
}

.d-icon-shoes:before {
    content: ""
}

.d-icon-pillow:before {
    content: ""
}

.d-icon-dress:before {
    content: ""
}

.d-icon-loungewear:before {
    content: ""
}

.d-icon-desktop:before {
    content: ""
}

.d-icon-laptop:before {
    content: ""
}

.d-icon-gamepad1:before {
    content: ""
}

.d-icon-wireless:before {
    content: ""
}

.d-icon-projector:before {
    content: ""
}

.d-icon-drone1:before {
    content: ""
}

.d-icon-drone2:before {
    content: ""
}

.d-icon-radio:before {
    content: ""
}

.d-icon-battery:before {
    content: ""
}

.d-icon-plugin:before {
    content: ""
}

.d-icon-memory:before {
    content: ""
}

.d-icon-ball:before {
    content: ""
}

.d-icon-basketball1:before {
    content: ""
}

.d-icon-babycare:before {
    content: ""
}

.d-icon-card:before {
    content: ""
}

.d-icon-gamepad2:before {
    content: ""
}

.d-icon-camera1:before {
    content: ""
}

.d-icon-camera2:before {
    content: ""
}

.d-icon-babywear:before {
    content: ""
}

.d-icon-abacus:before {
    content: ""
}

.d-icon-pot:before {
    content: ""
}

.d-icon-freezer:before {
    content: ""
}

.d-icon-cook:before {
    content: ""
}

.d-icon-student:before {
    content: ""
}

.d-icon-category:before {
    content: ""
}

.d-icon-basketball2:before {
    content: ""
}

.d-icon-watch:before {
    content: ""
}

.d-icon-tcard:before {
    content: ""
}

.d-icon-heartbeat:before {
    content: ""
}

.d-icon-watch-round:before {
    content: ""
}

.d-icon-washbowl:before {
    content: ""
}

.d-icon-bridge-lamp:before {
    content: ""
}

.d-icon-lamp:before {
    content: ""
}

.d-icon-sofa:before {
    content: ""
}

.d-icon-sofa2:before {
    content: ""
}

.d-icon-bed:before {
    content: ""
}

.d-icon-table:before {
    content: ""
}

.d-icon-table-lamp:before {
    content: ""
}

.d-icon-table-tv:before {
    content: ""
}

.d-icon-mirror:before {
    content: ""
}

.d-icon-volume:before {
    content: ""
}

.d-icon-bars2:before {
    content: ""
}

.d-icon-bars:before {
    content: ""
}

.d-icon-phone:before {
    content: ""
}

.d-icon-user:before {
    content: ""
}

.d-icon-search:before {
    content: ""
}

.d-icon-search2:before {
    content: ""
}

.d-icon-bag:before {
    content: ""
}

.d-icon-map:before {
    content: ""
}

.d-icon-info:before {
    content: ""
}

.d-icon-refresh:before {
    content: ""
}

.d-icon-left-arrow:before {
    content: ""
}

.d-icon-right-arrow:before {
    content: ""
}

.d-icon-down-arrow:before {
    content: ""
}

.d-icon-up-arrow:before {
    content: ""
}

.d-icon-ruler:before {
    content: ""
}

.d-icon-zoom:before {
    content: ""
}

.d-icon-right-circle:before {
    content: ""
}

.d-icon-left-circle:before {
    content: ""
}

.d-icon-up-circle:before {
    content: ""
}

.d-icon-down-circle:before {
    content: ""
}

.d-icon-angle-right:before {
    content: ""
}

.d-icon-angle-left:before {
    content: ""
}

.d-icon-angle-up:before {
    content: ""
}

.d-icon-angle-down:before {
    content: ""
}

.d-icon-star:before {
    content: ""
}

.d-icon-star-full:before {
    content: ""
}

.d-icon-heart:before {
    content: ""
}

.d-icon-heart-full:before {
    content: ""
}

.d-icon-close:before {
    content: ""
}

.d-icon-play-circle:before {
    content: ""
}

.d-icon-home:before {
    content: ""
}

.d-icon-filter-2:before {
    content: ""
}

.d-icon-switch:before {
    content: ""
}

.d-icon-switch-left-on:before {
    content: ""
}

.d-icon-switch-right-on:before {
    content: ""
}

.d-icon-filter-3:before {
    content: ""
}

.d-icon-check:before {
    content: ""
}

.d-icon-alert-solid:before {
    content: ""
}

.d-icon-headphone:before {
    content: ""
}

.d-icon-mode-grid:before {
    content: ""
}

.d-icon-mode-list:before {
    content: ""
}

.d-icon-cancel:before {
    content: ""
}

.d-icon-instagram:before {
    content: ""
}

.d-icon-solid-check:before {
    content: ""
}

.d-icon-play-solid:before {
    content: ""
}

.d-icon-mobile:before {
    content: ""
}

.d-icon-minus:before {
    content: ""
}

.d-icon-plus:before {
    content: ""
}

.d-icon-wifi:before {
    content: ""
}

.d-icon-times:before {
    content: ""
}

.d-icon-times-circle:before {
    content: ""
}

.d-icon-random:before {
    content: ""
}

.d-icon-th-list:before {
    content: ""
}

.d-icon-rocket:before {
    content: ""
}

.d-icon-map-marker:before {
    content: ""
}

.d-icon-birthday-cake:before {
    content: ""
}

.d-icon-gift:before {
    content: ""
}

.d-icon-female:before {
    content: ""
}

.d-icon-clock:before {
    content: ""
}

.d-icon-comments:before {
    content: ""
}

.d-icon-rotate-left:before {
    content: ""
}

.d-icon-rotate-right:before {
    content: ""
}

.d-icon-reading:before {
    content: ""
}

.d-icon-feeder:before {
    content: ""
}

.d-icon-birthday-cake2:before {
    content: ""
}

.d-icon-skirt:before {
    content: ""
}

.d-icon-toy:before {
    content: ""
}

.d-icon-butterfly:before {
    content: ""
}

.d-icon-babycare2:before {
    content: ""
}

.d-icon-butterfly2:before {
    content: ""
}

.d-icon-dinner-set:before {
    content: ""
}

.d-icon-ball2:before {
    content: ""
}

.d-icon-apple:before {
    content: ""
}

.d-icon-icecream:before {
    content: ""
}

.d-icon-cake:before {
    content: ""
}

.d-icon-meat:before {
    content: ""
}

.d-icon-bread:before {
    content: ""
}

.d-icon-cocktail:before {
    content: ""
}

.d-icon-food:before {
    content: ""
}

.d-icon-birthday-cake3:before {
    content: ""
}

.d-icon-cash:before {
    content: ""
}

.d-icon-service2:before {
    content: ""
}

.d-icon-car:before {
    content: ""
}

.d-icon-ball3:before {
    content: ""
}

.d-icon-compare:before {
    content: ""
}

.d-icon-jar:before {
    content: ""
}

.d-icon-radish:before {
    content: ""
}

.d-icon-arrow-down:before {
    content: ""
}

.d-icon-arrow-left:before {
    content: ""
}

.d-icon-arrow-right:before {
    content: ""
}

.d-icon-arrow-up:before {
    content: ""
}

.d-icon-earth:before {
    content: ""
}

.d-icon-long-arrow-left:before {
    content: ""
}

.d-icon-long-arrow-right:before {
    content: ""
}

.d-icon-long-arrow-down:before {
    content: ""
}

.d-icon-long-arrow-up:before {
    content: ""
}

.d-icon-pill:before {
    content: ""
}

.d-icon-cylinder:before {
    content: ""
}

.d-icon-medical-bag:before {
    content: ""
}

.d-icon-graph:before {
    content: ""
}

/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/
/*==========================================================*/


/* Deepak CSS  */

.section-gap-medium .r_gap {
    row-gap: 80px;
}

.section-gap-medium .list_img img {
    width: 100%;
}

.section-gap-medium .list_text {
    margin-left: 80px;
}

.section-gap-medium .list_text h2 {
    font-size: 58px;
    margin-bottom: 30px;
    color: var(--color-primary);
}

.section-gap-medium .list_text p {
    font-size: 18px;
}

.account-sidebar ul li {
    list-style: none;
}

.account-sidebar ul li a {
    color: #999;
    font-family: var(--font-secondary) !important;
}

.account-sidebar ul {
    row-gap: 20px;
    display: grid;
}

.account-sidebar ul li.active a {
    color: #000;
}

.section-gap-medium .section-title h2 {
    font-size: 24px;
}

.account_text h5 {
    font-size: 20px;
    color: #777;
    border-bottom: solid 1px #777;
    padding-bottom: 12px;
    margin-bottom: 20px;
}

.account_text p {
    margin-bottom: 4px;
}

.account_text a {
    text-decoration: underline;
}


.order_p .order_item {
    background-color: #f4f4f4;
    border-radius: 10px;
}

.order_p .order_item:not(:last-child) {
    margin-bottom: 20px;
}

.order_list .order_list_img {
    width: 70px;
    height: 70px;
    border-radius: 4px;
    background-color: #e2e2e2;
    padding: 4px;
}

.order_list .order_list_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.order_list p {
    font-family: var(--font-secondary) !important;

}

.order_list span {
    font-size: 14px;
}

.order_list:not(:last-child) {
    margin-bottom: 20px;
}

.order_p .order_head,
.order_p .order_body,
.order_p .order_foot {
    padding: 18px 22px;
}

.order_p .order_item .in_delivery {
    font-size: 12px;
    color: #26B723;
    padding: 4px 12px;
    background: #fff;
    border-radius: 30px;
}

.order_p .order_body {
    border: solid 1px #ddd;
    border-left: 0;
    border-right: 0;
}

.order_bar::before {
    content: "";
    left: 0;
    top: 16px;
    height: 1px;
    width: 100%;
    z-index: -1;
    position: absolute;
    background-color: #ccc;
}

.order_bar .bar_list span {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ddd;
    font-weight: 500;
    font-size: 15px;
}

.order_bar .bar_list span.active {
    background-color: #26B723;
    color: #fff;
}

.order_bar .bar_list h6 {
    font-size: 16px;

}

.order_bar .bar_list p {
    font-size: 14px;
    color: #999;
}

.delivery_info {
    padding: 22px;
    border-radius: 10px;
    margin-top: 30px;
    background-color: #f4f4f4;
}

.delivery_info {
    padding: 22px;
    border-radius: 10px;
    margin-top: 30px;
    background-color: #f4f4f4;
}

.delivery_info ul li {
    list-style: none;
}

.delivery_info ul li:not(:last-child) {
    border-bottom: solid 1px #ccc;
}

.delivery_info .info_row {
    padding: 20px 0;
}

.delivery_info .info_row span {
    min-width: 230px;
    margin-right: 20px;
    color: #999;
}

.delivery_info .info_row p {
    width: 100%;
}

.item_order {
    padding-bottom: 30px;
    border-bottom: solid 1px #ccc;
}

.item_order .head {
    margin: 20px 0 20px 0;
}

.sub_total {
    width: 280px;
}

.sub_total .sub_total_list p {
    color: #999;
}

.sub_total .sub_total_list p b {
    color: #000;
}

.sub_total .sub_total_list.total {
    border-top: solid 1px #ccc;
    padding-top: 10px;
}

.sub_total .sub_total_list:not(:last-child) {
    margin-bottom: 16px;
}

.sub_total .sub_total_list span {
    font-size: 16px;
    font-weight: 700;
}

.sub_total .sub_total_list.discount {
    color: #FC0101;
}

.section-title a {
    color: #FC4C01;
    text-decoration: underline;
}

.address_item {
    row-gap: 20px;
    display: grid;
}

.address_item .address_list {
    padding: 16px;
    border-radius: 10px;
    background-color: #f4f4f4;
}

.address_item .address_list_img {
    min-width: 90px;
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
    background-color: #e2e2e2;
}

.address_item .address_list_img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.address_item .address_list_text h5 {
    font-size: 18px;
}

.address_item .address_list_text p {
    font-size: 14px;
    color: #999;
}

.address_item .address_list_text a {
    font-size: 14px;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 6px 12px;
    color: #999;
    font-weight: 500;
}

.address_info {
    padding: 20px;
    border-radius: 10px;
    background-color: #f4f4f4;
}

.address_info ul li {
    border-bottom: solid 1px #ccc;
    list-style: none;
}

.address_info .info_row {
    padding: 20px 0;
}

.address_info .info_row span {
    min-width: 230px;
    margin-right: 20px;
    color: #999;
}

.address_info .info_row input,
.address_info .info_row select {
    background: none;
    border: 0;
    width: 100%;
    color: #000;
}

.address_info .info_row input::placeholder {
    color: #000;
}

:focus-visible {
    outline: none;
}

.address_info .address_info_btn a {
    font-size: 14px;
    border: solid 1px #ccc;
    border-radius: 5px;
    padding: 6px 12px;
    color: #999;
    font-weight: 500;
    min-width: 80px;
    justify-content: center;
}

.blog_detail_head ul li {
    list-style: none;
}


@media (max-width:1199.80px) {
    .section-gap-medium .list_text {
        margin-left: 50px;
    }

    .section-gap-medium .list_text h2 {
        font-size: 48px;
    }

    .section-gap-medium .r_gap {
        row-gap: 60px;
    }

    .section-gap-medium {
        padding: 40px 0px;
    }

}

@media (max-width:991.80px) {
    .section-gap-medium .list_text {
        margin-left: 0;
    }

    .section-gap-medium .list_text h2 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .section-gap-medium .list_text p {
        font-size: 16px;
    }

    .section-gap-medium .r_gap {
        row-gap: 40px;
    }

    .section-gap-medium {
        padding: 30px 0px;
    }

    .account-sidebar ul {
        display: flex;
        column-gap: 20px;
        overflow: auto;
        margin-bottom: 20px;
    }

    .account-sidebar ul li {
        border: solid 1px #999;
        border-radius: 50px;
        padding: 6px 16px;
        white-space: nowrap;
        font-size: 14px;
    }

    .account-sidebar ul li.active {
        border-color: #000;
    }

    .section-gap-medium .section-title h2 {
        font-size: 22px;
    }

    .account_text h5 {
        font-size: 18px;
    }

    .account_text p {
        font-size: 15px;
    }

    .account-sidebar ul li a {
        font-weight: 500;
    }

    .account-sidebar ul {
        column-gap: 10px;
    }

    .subheader .breadcrumb {
        margin-bottom: 0;
    }



}

@media (max-width:767.80px) {

    .section-gap-medium .list_text {
        margin-top: 20px;
    }

    .section-gap-medium .r_gap {
        row-gap: 20px;
    }

    .section-gap-medium .list_text h2 {
        font-size: 22px;
        margin-bottom: 10px;
    }

    .section-gap-medium .section-title h2 {
        font-size: 20px;
    }

    .account_text h5 {
        font-size: 16px;
        padding-bottom: 6px;
        margin-bottom: 12px;
    }

    .account-sidebar ul li {
        font-size: 12px;
    }

    .delivery_info .info_row span {
        min-width: 140px;
        margin-right: 20px;
        color: #999;
    }

    .address_info .info_row span {
        min-width: 140px;
    }

}

@media (max-width:575.80px) {

    .order_p .order_head,
    .order_p .order_body,
    .order_p .order_foot {
        padding: 14px 14px;
    }

    .order_list .order_list_img {
        width: 60px;
        height: 60px;
    }

    .order_p .order_item h5 {
        font-size: 18px;
    }

    .address_item .address_list_img {
        min-width: 70px;
        height: 80px;
    }

    .address_item .address_list_text h5 {
        font-size: 16px;
    }

    .address_item .address_list_text p {
        font-size: 13px;
    }

    .address_item .address_list_text a {
        font-size: 13px;
        padding: 4px 10px;
    }

    .order_p .order_item:not(:last-child) {
        margin-bottom: 12px;
    }

    .order_bar .bar_list span {
        width: 28px;
        height: 28px;
        font-size: 12px;
    }

    .order_bar .bar_list h6 {
        font-size: 14px;
    }

    .order_bar .bar_list p {
        font-size: 12px;
        color: #999;
    }

    .delivery_info {
        padding: 20px 14px 14px;
        margin-top: 20px;
    }

    .delivery_info h5 {
        font-size: 18px;
    }

    .delivery_info .info_row span,
    .delivery_info .info_row p {
        font-size: 15px;
    }

    .delivery_info .info_row {
        padding: 12px 0;
    }

    .delivery_info .info_row span {
        min-width: 110px;
        margin-right: 20px;
        color: #999;
    }

    .subheader .breadcrumb-item a {
        font-size: 14px;
    }

    .sub_total .sub_total_list:not(:last-child) {
        margin-bottom: 10px;
    }

    .sub_total .sub_total_list p,
    .sub_total .sub_total_list span {
        font-size: 15px;
    }

    .address_item {
        row-gap: 16px;
    }

    .address_info .info_row span,
    .address_info .info_row input {
        font-size: 15px;
    }

    .address_info .info_row span {
        min-width: 110px;
    }

    .address_info .info_row {
        padding: 12px 0;
    }






}


.accordion-button:not(.collapsed) {
    background: #fff;
}

.accordion-button:focus{
    box-shadow: 0 0 0.25rem 0 rgba(13, 110, 253, 0.25);
}